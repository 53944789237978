<template>
  <b-modal
    id="modal-template-delete"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ trans('templates.modals.delete.title') }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <!--begin::content-->
      <p class="" v-html="trans('templates.modals.delete.desc', { templatename: templateName })">
      </p>
      <!--end::content-->
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="danger"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="deleteTemplate()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('templates.modals.delete.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('templates.modals.delete.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import * as objectPath from 'object-path'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'ModalTemplatesDelete',
    props: {
      templateData: {
        type: Object,
        required: true
      }
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const submitButtonAnimation = ref(false)
      const templateName = computed(() => objectPath.get(props, 'templateData.name', trans('templates.modals.delete.missingName'))) // eslint-disable-line max-len
      const templateId = computed(() => objectPath.get(props, 'templateData.id', false))

      const deleteTemplate = async () => {
        submitButtonAnimation.value = true

        const deleteFunc = fb.functions.httpsCallable('backendTemplatesDeleteTemplate')
        await deleteFunc({
          id: templateId.value
        }).then((res) => {
          submitButtonAnimation.value = false

          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.modals.delete.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.modals.delete.toast.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('templates.modals.delete.toast.success.msg'), {
              title: trans('templates.modals.delete.toast.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            // Update the "oppgaver"-count
            root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len

            root.$bvModal.hide('modal-template-delete')
          }
        }).catch((err) => {
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('templates.modals.delete.toast.error.msg', { error: err }), {
            title: trans('templates.modals.delete.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
          console.log('ERRORS:', err)
        })

        return true
      }

      const initializeModal = () => {
        submitButtonAnimation.value = false
      }

      return {
        trans,
        transChoice,
        capitalize,
        initializeModal,
        deleteTemplate,
        templateName,
        submitButtonAnimation
      }
    }
  }
</script>

<style lang="scss">

</style>
